* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

body {
  background-color: #ffffff;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

::-webkit-scrollbar-track {
  background-color: #F5F7F9;
}

::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.7rem;
  background-color: #F5F7F9;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border-top: 2px #F5F7F9 solid;
  border-bottom: 2px #F5F7F9 solid;
  border-right: 2.3px #F5F7F9 solid;
  border-left: 2.3px #F5F7F9 solid;
  background-color: #ADB9CA;
}

::-webkit-scrollbar-thumb:horizontal {
  border-right: 2px #F5F7F9 solid;
  border-left: 2px #F5F7F9 solid;
  border-top: 2.3px #F5F7F9 solid;
  border-bottom: 2.3px #F5F7F9 solid;
}

body,
input,
button,
select,
textarea {
  font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 500;
}

a {
  text-decoration: none;
  margin: 0;
}

button {
  cursor: pointer;
}

input,
button {
  border: 0;
  outline: 0;
}

.modal-default-wrapper-overlay {
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}

.modal-default-wrapper {
  z-index: 99999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-height: fit-content;
  width: fit-content;
  background-color: #fff;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.text-hide-opacity,
.text-hide-tooltip {
  font-size: 0.875rem !important;
  pointer-events: auto !important;
  opacity: 1 !important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.04) !important;
  min-width: 500px !important;
  text-align: left !important;
  font-weight: 400 !important;
}

.text-hide-opacity:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.text-hide-tooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.04) !important;
}

.tooltipMenu {
  font-size: 0.9rem !important;
  font-weight: bold !important;
}

.fullModal {
  background: #ffffff;
  height: 100vh;
  /* opacity: 0.9; */
}

.fullModal header button {
  margin-right: 30px;
  margin-top: 30px;
  background: #ff6e00;
  width: 30px;
  height: 30px;
}


.accordeonDefault {
 padding: 20px !important; 
 backgroundColor: #FFF5EE !important; 
 borderRadius: 10px !important;
 marginTop: 10px !important; 
 display: grid !important
}

.accordeonDefault button{
  font-weight: bold;
  color: rgb(68, 84, 106);
  background-color: transparent
}

.tableDefault{
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 10px
}

.tableDefault thead tr th{
  height: 30px;
}

.tableDefault tbody tr td{
  height: 33px 
}


.tableDefaultLeft{
  background-color: #FFFFFF;
  /* width: 100%; */
  border-radius: 10px
}

.tableDefaultLeft thead tr th{
  height: 30px;
  text-align: left;
  background-color: #dddddd;
  border-radius: 5px 5px 0 0;
  padding: 5px;
}

.tableDefaultLeft tbody tr td{
  height: 33px 
}

.legendChart{
  display: flex;
  justify-content: center;
}

.legendChart div{
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items:center
}

.legendChart div div{
  width: 15px;
  height: 15px;
  border-radius: 6px;
}

.legendChart div span{
  font-weight:500;
  font-size: 12px;
}

.dataFormat{
  height: 45px;
  border: 2px solid #58798D;
  border-radius: 10px
}

.deflator-infrator{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 40px;
  padding: 15px;
  gap: 5px;
  border: 1px solid #ff6e00
}

.datapicker-general input {
  padding-left: 15px;
  font-size: 0.875rem;
  color: #58798D;
  font-weight: 500
}

.datapicker-general ::placeholder {
  font-size: 0.875rem;
  color: #58798D;
  font-weight: 500;
}

.accordeonMenu div{
  bor-shadow: none !important;
  width: 100% !important;
  backgroundColor: transparent !important; 
  border: 0px !important;
}

.infoAlert{
  padding:10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E6E6FA;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer
}
.infoAlert svg{
  font-size: 20px
}